import React, { Component } from "react";
import { Helmet } from 'react-helmet'; // For controlling meta tags

import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import { getCookie, setCookie } from "../helpers/cookie-helper";
import { TO_PRIVACY_POLICY } from "../helpers/routesConstants";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' 
import Toggle from "./toggle";
import { COOKIE_NAME, SEND_STATISTICS } from "../helpers/constants";
export class CookieView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			IAgree: false,
			crossButtonClicked: false,
			sendStatistics: "Yes",
			preferenceSubmitted: false,
		};
	}
	handleSelect = (val) => {
		this.setState({
			sendStatistics: val
		})
	}
	onCrossButtonClick = () =>{
		this.setState({
			IAgree: true
		})
	}
	onAgreeClick = () => {
		let getCookieValue;
		localStorage.setItem(SEND_STATISTICS, this.state.sendStatistics);
		this.setState(
			{
				IAgree: true,
			},
			() => {
				setCookie(COOKIE_NAME, true, {
					maxAge: 60 * 60 * 24 * 365,
				});
				console.log("getCookie",getCookie(COOKIE_NAME))
				getCookieValue = getCookie(COOKIE_NAME);
				window.location.reload(false);
			}
		);
	};
	onSubmitPrefrencesClick = () => {
		this.setState({
			preferenceSubmitted: true
		})	
		localStorage.setItem(SEND_STATISTICS, this.state.sendStatistics);
		this.onAgreeClick()
		this.onCrossButtonClick()
	};
	handleClick = () => {
        var thisObj = this;
		const headerStyleObj = {
			fontSize: 20,
			fontFamily:'Helvetica',
			textAlign: "center",
			fontWeight:'bold'
		}
		const subStyleObj = {
			fontSize: 14 ,
			fontFamily:'Helvetica',
			textAlign: "center",
		}
		const bodyStyle = {
			border:"3px solid rgba(0, 0, 0, 0.05)",
			maxWidth:"700px",
			padding:"20px"
		}
		const requiredBodyStyle = {
			fontSize: 20,
			fontFamily:'Helvetica',
			fontWeight:'bold',
			textAlign: "left",
		}
		const longTextBodyStyle = {
			fontSize: 14,
			fontFamily:'Helvetica',
			textAlign: "left",
		}
		const acceptButtonStyle = {
			marginTop:"80px",
			textAlign:"center",
			marginBottom:"20px",
		}
		const ButtonStyle = {
			marginBottom:"20px",
		}
	
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
					<div>
						<Helmet> <meta name="robots" content="noindex, nofollow" /> </Helmet>
                    <div className="modal-content " style={{textAlign:"center",overflow:"scroll",fontFamily:'Helvetica',border:"3px solid black",height:"80vh"}}>
						<div className="modal-body">
                        <div  style={{position : "absolute",right:"0px",padding:"10px",cursor:"pointer"}}> <i onClick={()=>onClose()} class="fa fa-close"></i></div>
							<div style={ButtonStyle}>
								<img src="/assets/img/logoWithText.png" alt="Logo"/>
							</div>
                            <p style={headerStyleObj}>Your Choices Regarding Cookies on this Site</p>
							<p style={subStyleObj}>This site may use cookies or related technologies such as web beacons, pixel tags, and Flash objects<br/> (“Cookies”) as described below. You can learn more about how this site uses cookies and related <br/> technologies by reading our privacy policy linked below. </p>
							<div style={bodyStyle} >
								<p style={requiredBodyStyle}>Required Cookies</p>
								<p style={longTextBodyStyle}>These cookies are necessary to enable the basic features of this site to function, such as providing prices if you are in the United States, secure log-in or remembering how far you are through an order.</p>
							</div>
							<div style={bodyStyle} >
								<p style={requiredBodyStyle}>Statistics</p>
								<p style={longTextBodyStyle}>With these cookies, we collect anonymized usage data for our website. For example, we can see which content is interesting for our visitors and which resolutions are used. We use the information to optimize our website to provide you with the best possible user experience.</p>
								<Toggle style={{cursor:"pointer"}} onSelect={this.handleSelect}/>
							</div>
							<div style={acceptButtonStyle}>
								<div style={ButtonStyle}>
                            		<button disabled={this.state.preferenceSubmitted} className="btn btn-primary btn-lg btn-block" style={{backgroundColor:"#6E7180"}} onClick={() => {this.onSubmitPrefrencesClick()}}>Submit Preferences</button>
								</div>
								<div style={ButtonStyle}>
                            		<button className="btn btn-primary btn-lg btn-block" style={{backgroundColor:"#0D122A"}} onClick={() => {this.onAgreeClick();onClose()}}>Accept All</button>
								</div>
								<div style={ButtonStyle}>
									<a href="https://www.intrepidcs.com">Return to Main Screen</a>
								</div>
								<div style={ButtonStyle}>
									<a href={TO_PRIVACY_POLICY}>Privacy Policy </a>
								</div>
							</div>
						</div>
                    </div>
					</div>
                )
            }
        }) 
    }
	componentDidMount = () => {
		let getCookieValue = getCookie(COOKIE_NAME);
		let page = (window.location.href.split('/')).includes("privacy-policy");
		let localStatisticsData = localStorage.getItem(SEND_STATISTICS);
		this.setState({
			IAgree: getCookieValue,
		},()=>{
			if(getCookieValue === "undefined" || getCookieValue === null || !getCookieValue){
				if(!page)
				this.handleClick()
			} else if (!localStatisticsData){
				this.handleClick()
			}
		});
		
	};
	render() {
		return (
			
			<div>
				 {/* {!this.state.IAgree && (
					<div className="cookie-consent-banner">
						<div className="d-flex justify-content-end">
							<button 
							onClick={this.onCrossButtonClick}
							className="cross_button">
								x
							</button>
						</div>
						
						<div className="cookie-consent-banner__inner">
							<div className="cookie-consent-banner__copy">
								<div className="cookie-consent-banner__description">
									By continuing to browse this site, you are agreeing to use of
									cookies, whose purpose it is to provide web analytics and
									measurements of visitor traffic and browsing behavior
								</div>
							</div>

							<div className="cookie-consent-banner__actions">
								<Button
									onClick={this.onAgreeClick}
									className="cookie-consent-banner__cta">
									I Agree
								</Button>
								<Link to={TO_PRIVACY_POLICY	} >
									<Button className="cookie-consent-banner__cta">
										Privacy Policy
									</Button>
								</Link>
							</div>
						</div>
						
					</div>
				)} */}
			 </div> 
		);
	}
}
